import React from "react";

export const TermsConditionsEvents = () => {
    return (
        <div id="snowpage">
            <div className="container">
                <div className="">
                    <h1>Termeni și Condiții Evenimente</h1>
                    <h2>Condiții de restituire a taxei de participare pentru participanții la evenimentele organizate de DORMI IN GHETE ADVENTURES S.R.L.</h2>

                    <p>Restituirea integrală/parțială (în funcție de costurile deja antrenate de către organizatori) a sumelor achitate de participanții la tabără/excursie se poate efectua în următoarele situații, cu respectarea condițiilor de mai jos:</p>

                    <h3>1. Anularea evenimentului de către organizatori</h3>
                    <p>Participanții vor beneficia de restituirea integrală/parțială (în funcție de costurile deja antrenate de către organizatori) a sumelor achitate în cazul în care tabăra/excursia este anulată din motive neimputabile organizatorilor, cum ar fi:</p>
                    <ul>
                        <li>Condiții meteorologice extreme (avertizări meteo de tip cod roșu, viscol, zăpadă abundentă etc.) care duc la imposibilitatea desfășurării taberei/excursiei și pun în pericol siguranța participanților.</li>
                        <li>Întreruperea taberei din motive de sănătate publică (restricții de călătorie, carantină etc.) care fac imposibilă desfășurarea taberei/excursiei sau participarea la aceasta.</li>
                        <li>Situații de forță majoră (de exemplu, dezastre naturale, greve, conflicte armate etc.) care împiedică desfășurarea taberei/excursiei.</li>
                    </ul>

                    <h3>2. Participantul se retrage din eveniment în urma unei situații urgente sau critice</h3>
                    <p>În cazul în care participantul nu poate desfășura activitățile taberei/excursiei din motive urgente sau critice neștiute de acesta la momentul înscrierii, restituirea integrală/parțială (în funcție de costurile deja antrenate de către organizatori) a sumelor achitate va fi analizată în următoarele situații:</p>
                    <ul>
                        <li>Incapacitate medicală (accidente, fracturi, boli grave neprevăzute la momentul înscrierii etc.).</li>
                        <li>Deces în familie, limitindu-se la partener de viață, părinți, copii, frați sau surori.</li>
                        <li>Alte situații de forță majoră.</li>
                    </ul>
                    <p>Restituirea va fi posibilă doar cu prezentarea documentelor justificative adecvate (ex: certificat medical, certificat de deces etc.).</p>

                    <h3>3. Retragerea voluntară cu înlocuitor</h3>
                    <p>Dacă participantul decide să se retragă din tabără/excursie, acesta va putea solicita restituirea sumei achitate, cu condiția să anunțe în scris organizatorii și să aducă un participant înlocuitor cu cel puțin 5 zile lucrătoare înainte de începerea evenimentului. Restituirea va fi efectuată integral, cu excepția eventualelor taxe administrative care vor fi stabilite de organizatori.</p>

                    <p>În toate cazurile de restituire, solicitările vor fi procesate în termen de 14 zile lucrătoare de la primirea documentației complete.</p>

                    <p>Organizatorii își rezervă dreptul de a solicita suplimentar orice alte informații sau documente relevante în vederea verificării situației.</p>

                    <p>Vă mulțumim pentru înțelegere și colaborare!</p>
                </div>
            </div>
        </div>
    );
}