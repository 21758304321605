import React from "react";
import {NavLink} from 'react-router-dom';

export const Contact = (props) => {
  return (
    <div>
      <div id="contact">
        <div className="container">
            <div className="social">
                <div>
                    <NavLink className="link" to={"/termeni-si-conditii-evenimente"}>Termeni și Condiții Evenimente</NavLink>
                </div>
                <ul>
                  <li>
                    <a href={props.data ? props.data.instagram : "/"} rel="noreferrer" target="_blank">
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"} rel="noreferrer" target="_blank">
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>

                  <li>
                    <a href={props.data ? props.data.mail : "/"} rel="noreferrer" target="_blank">
                      <i className="fa fa-google"></i>
                    </a>
                  </li>
                </ul>
            </div>
        </div>
      </div>
    </div>
  );
};
